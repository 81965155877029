import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import api from "../../../../api";
import Responsive from "../../../../assets/config/Responsive";
import DoubleRightIcon from "../../../../assets/icons/JSX/DoubleRightIcon";
import Colors from "../../../../assets/styles/Colors";
import Accordion from "../../../../components/Accordion";
import Button from "../../../../components/Button";
import CustomDropdown from "../../../../components/CustomDropdown";
import CustomLink from "../../../../components/CustomLink";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import { RootState, useAppSelector } from "../../../../store";
import { AuthState } from "../../../../store/authSlice";
import UploadFile from "../../../../components/UploadFile";
import { toast } from "react-toastify";
import {
  DeactivatedPolicy,
  EnabledLanguage,
  PetDetailPageType,
  ScheduleType,
  currencySymbol,
  georgianFormatter,
} from "../../../../domain/types";
import AdminPaymentSchedule from "../../../../components/AdminPaymentSchedule";
import PetSmallIcon from "../../../../assets/img/JSX/PetSmallIcon";
import PetIcon from "../../../../assets/img/JSX/PetIcon";

const AdminTplSoldPolicyDetail = () => {
  //
  const { policyId: policyIdStr = "0" } = useParams();
  const policyId = parseInt(policyIdStr);
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const [activeTab, setActiveTab] = useState<string>("1");
  //
  const [language] = useState<EnabledLanguage>(lang);
  //
  const navigate = useNavigate();
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const { t } = useTranslation();
  //
  //
  const [showPolicySetting, setShowPolicySetting] = useState<boolean>(false);
  //
  const [activePolicyStatus, setActivePolicyStatus] = useState("");
  //
  //
  const [showActivePolicySetting, setShowActivePolicySetting] =
    useState<boolean>(false);
  //
  // active policy mutation
  const [activePolicy, { isLoading: isActivePolicyLoading }] =
    api.useActivePolicyMutation();
  //
  //
  //
  // deactive policy mutation
  const [deActivePolicy, { isLoading: isDeActivePolicyLoading }] =
    api.useDeactivePolicyMutation();
  //
  // countries list
  const { data: countries = [], isLoading: isCountriesLoading } =
    api.useGetCountriesQuery(undefined);
  //
  const {
    data: adminPolicyDetail,
    isLoading: isAdminPolicyDetailLoading,
    refetch: refetchAdminPolicy,
  } = api.useGetAdminPolicyDetailQuery(policyId, {
    skip:
      auth.user?.userType === "Frani-Admin" ||
      auth.user?.userType === "Frani-Product-Owner"
        ? false
        : true,
  });
  //
  // get travel policy deactive reason
  const { data: deactiveReasonArr, isLoading: isDeaactiveReasonArrLoading } =
    api.useGetAdminPolicyDeactiveReasonQuery(
      {
        categoryId: (adminPolicyDetail?.productCategoryId as number) || 0,
        isActive: true,
      },
      {
        skip: adminPolicyDetail?.productCategoryId ? false : true,
      }
    );
  //
  //
  const { data: companyPolicyDetail, isLoading: isCompanyPolicyDetailLoading } =
    api.useGetAdminPolicyByIdQuery(policyId, {
      skip: auth.user?.userType === "Insurance" && policyId ? false : true,
    });
  //
  //
  const [deactiveInfo, setDeactiveInfo] = useState<Partial<DeactivatedPolicy>>({
    policyId: policyId,
  });
  //
  //
  const setDeactiveFields = (field: keyof DeactivatedPolicy, value: any) => {
    setDeactiveInfo({
      ...deactiveInfo,
      [field]: value,
    });
  };
  //
  //
  const setDeactiveTranslatedField = (
    field: keyof DeactivatedPolicy,
    language: EnabledLanguage,
    value: any
  ) => {
    setDeactiveInfo({
      ...deactiveInfo,
      translations: {
        ...deactiveInfo.translations,
        [language]: {
          ...deactiveInfo?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  const _activePolicyFuntion = async (
    policyId: number,
    policyDeactivationId: number,
    policyStatus: string
  ) => {
    //
    if (policyId && policyDeactivationId && policyStatus) {
      try {
        await activePolicy({
          policyId,
          policyDeactivationId,
          policyStatus,
        }).unwrap();
        //
        setShowActivePolicySetting(false);
        //
        toast.success(`${t("policy")} ${t("activatedSuccessfully")}`);
        //
        refetchAdminPolicy();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
    //
  };
  //
  //
  const _deActivePolicyFuntion = async () => {
    //
    if (deactiveInfo) {
      try {
        await deActivePolicy({
          cancellationPenalty: Number(deactiveInfo.cancellationPenalty),
          comment: deactiveInfo.comment,
          deactivatedDate: deactiveInfo.deactivatedDate,
          docUrl: deactiveInfo.docUrl,
          earnedPremium: Number(deactiveInfo.earnedPremium),
          policyId: policyId,
          policyDeactivationReasonId: deactiveInfo.policyDeactivationReasonId,
          refundAmount: Number(deactiveInfo.refundAmount),
          translations: deactiveInfo.translations,
        }).unwrap();
        //
        setShowPolicySetting(false);
        //
        toast.success(`${t("policy")} ${t("deactivated")} !`);
        //
        refetchAdminPolicy();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
    //
  };
  //
  //
  const policy: PetDetailPageType =
    (adminPolicyDetail as never as PetDetailPageType) ||
    (companyPolicyDetail as never as PetDetailPageType);
  //
  //
  const startDate = dayjs(policy?.mainPolicy?.startDate);
  const endDate = dayjs(policy?.mainPolicy?.endDate);
  //
  //
  const insuranceDays = endDate.diff(startDate, "day");
  //
  //
  const orderId = policy?.mainPolicy?.orderItems?.[0]?.orderId;
  //
  const discount = policy?.mainPolicy?.orderItems?.[0]?.order?.discount;
  //
  const { data: paymentScheduleData, isLoading: isPaymentScheduleDataLoading } =
    api.useGetAdminPetPaymentScheduleQuery(
      {
        orderId,
      },
      {
        skip: orderId ? false : true,
      }
    );

  const { data: paymentTransactions, isLoading: isPaymentTransactionLoading } =
    api.useGetAdminPetTransactionsQuery(
      {
        orderId,
      },
      {
        skip: orderId ? false : true,
      }
    );
  //
  //
  const _scheduleArray = () => {
    //
    if (paymentScheduleData) {
      //
      const formatedData: ScheduleType = {
        nextPaymentAmount: georgianFormatter.format(
          paymentScheduleData.nextPaymentAmount
        ),
        totalPaymentAmount: georgianFormatter.format(
          paymentScheduleData.totalRemainedPaymentAmount
        ),
        nextPaymentDate: paymentScheduleData.nextPaymentDate,
        currency: paymentScheduleData.currency,
        schedule: paymentScheduleData.payments.map((item) => ({
          amount: georgianFormatter.format(item.amount),
          name: dayjs(
            item.details.scheduledPaymentDate ?? item.details.paymentDate
          ).format("MMM"),
          passed: item.status === "paid" ? true : false,
          active:
            dayjs(paymentScheduleData.nextPaymentDate).format("MMMM") ===
              dayjs(item.details.paymentDate).format("MMMM") &&
            item.status !== "paid"
              ? true
              : false,
        })),
      };
      //
      return formatedData;
      //
    } else {
      return undefined;
    }
  };
  //
  const paymentScheduleArray = _scheduleArray();
  //
  //
  // const refundAmount = policy?.refundAmount
  //   ? parseFloat(policy?.refundAmount) -
  //     (deactiveInfo?.earnedPremium || 0) -
  //     (deactiveInfo?.cancellationPenalty || 0)
  //   : null;
  //
  // main loading
  const MainLoading =
    isAdminPolicyDetailLoading ||
    isCompanyPolicyDetailLoading ||
    isActivePolicyLoading ||
    isDeActivePolicyLoading ||
    isPaymentTransactionLoading ||
    isDeaactiveReasonArrLoading ||
    isCountriesLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBlock: "20px",
        }}
      >
        <CustomLink
          text={t("prevPage")}
          leftLineIcon={true}
          onClick={() => navigate(-1)}
        />
        {(auth.user?.userType === "Frani-Admin" ||
          auth.user?.userType === "Frani-Product-Owner") && (
          <Button
            text={
              policy?.mainPolicy?.deactivatedPolicy
                ? `${t("policyActivation")}`
                : `${t("policyDeactivation")}`
            }
            btnStyleType={
              policy?.mainPolicy?.deactivatedPolicy ? "primary" : "transparent"
            }
            border={policy?.mainPolicy?.deactivatedPolicy ? false : true}
            onClick={() => {
              if (policy?.mainPolicy?.deactivatedPolicy) {
                setShowActivePolicySetting(!showActivePolicySetting);
              }
              //
              if (!policy?.mainPolicy?.deactivatedPolicy) {
                // show deactive form
                setShowPolicySetting(!showPolicySetting);
                //
                setDeactiveFields(
                  "refundAmount",
                  Number(policy?.refundAmount || 0)
                );
              }
            }}
          />
        )}
      </div>
      <hr />

      {/* active policy */}
      {showActivePolicySetting && (
        <Accordion title={t("changePolicyStatus")} openAccordion={true}>
          <GridBlock>
            {/* setShowActivePolicySetting */}
            <CustomDropdown
              title={t("choosePolicyStatus")}
              isMulti={false}
              value={activePolicyStatus}
              isClearable={false}
              onChange={(obj) => setActivePolicyStatus(obj.value)}
              options={[
                ...["ACTIVE", "CLOSED", "EXPIRED", "PENDING"].map((item) => ({
                  value: item,
                  label: item,
                })),
              ]}
            />
          </GridBlock>

          <br />

          <Button
            border={true}
            btnStyleType="transparent"
            fullWidth={true}
            text="გაგზავნა"
            disabled={activePolicyStatus ? false : true}
            onClick={() => {
              if (
                activePolicyStatus &&
                policyId &&
                policy?.mainPolicy?.deactivatedPolicy?.id &&
                window.confirm(`${t("certainToChangeStatus")}`)
              )
                _activePolicyFuntion(
                  policyId,
                  policy?.mainPolicy?.deactivatedPolicy?.id,
                  activePolicyStatus
                );
            }}
          />
        </Accordion>
      )}

      {/* active policy */}

      {/* deactive policy */}

      {showPolicySetting && (
        <Accordion title={t("policyDeactivation")} openAccordion={true}>
          <GridBlock>
            <Input
              id="deactive-date"
              name="deactive-date"
              label={t("selectDeactivationDate")}
              type="date"
              placeholder=""
              value={deactiveInfo.deactivatedDate || ""}
              onChange={(value) =>
                setDeactiveFields(
                  "deactivatedDate",
                  dayjs(value).format("YYYY-MM-DD")
                )
              }
              width={"fullWidth"}
            />

            {deactiveReasonArr && (
              <CustomDropdown
                title={t("deactivationReason")}
                isMulti={false}
                value={deactiveInfo.policyDeactivationReasonId}
                isClearable={false}
                onChange={(obj) =>
                  setDeactiveFields("policyDeactivationReasonId", obj.value)
                }
                options={[
                  ...deactiveReasonArr.map((item) => ({
                    value: item.id,
                    label: item?.translations[lang]?.title,
                  })),
                ]}
              />
            )}

            <Input
              id="earned-premium"
              name="earned-premium"
              label={t("earnedPremium")}
              type="number"
              placeholder=""
              value={deactiveInfo.earnedPremium || ""}
              onChange={(value) => setDeactiveFields("earnedPremium", value)}
              width={"fullWidth"}
            />

            <Input
              id="cancellation-penalty"
              name="cancellation-penalty"
              label={t("deactivationPenalty")}
              type="number"
              placeholder=""
              value={deactiveInfo.cancellationPenalty || ""}
              onChange={(value) =>
                setDeactiveFields("cancellationPenalty", value)
              }
              width={"fullWidth"}
            />

            <Input
              id="refunded-amount"
              name="refunded-amount"
              label={t("refundableAmount")}
              type="number"
              placeholder=""
              value={deactiveInfo.refundAmount || ""}
              onChange={(value) => setDeactiveFields("refundAmount", value)}
              width={"fullWidth"}
            />

            <UploadFile
              label={t("attachDocument")}
              onChange={(props) => setDeactiveFields("docUrl", props.url)}
              defaultFileName={deactiveInfo?.docUrl || ""}
            />
          </GridBlock>

          <br />

          <div>
            <TextAreaTitle>{t("comment")}</TextAreaTitle>
            <TextAreaStyle
              value={deactiveInfo.translations?.[language]?.title}
              onChange={(event) =>
                setDeactiveTranslatedField(
                  "comment",
                  language,
                  event.target.value
                )
              }
            ></TextAreaStyle>
          </div>

          <br />

          <Button
            border={true}
            btnStyleType="transparent"
            fullWidth={true}
            text={t("policyDeactivate")}
            disabled={
              deactiveInfo.policyDeactivationReasonId &&
              deactiveInfo.deactivatedDate &&
              deactiveInfo.cancellationPenalty &&
              deactiveInfo.policyId &&
              deactiveInfo.earnedPremium
                ? false
                : true
            }
            onClick={() => {
              window.confirm(`${t("certainToDeactivate")}`) &&
                _deActivePolicyFuntion();
            }} // deactive policy
          />
        </Accordion>
      )}
      {/*  */}
      {/*  */}
      <TabBox>
        <Tab onClick={() => setActiveTab("1")} $active={activeTab === "1"}>
          {t("policyInfo")}
        </Tab>
        <Tab onClick={() => setActiveTab("2")} $active={activeTab === "2"}>
          {t("transactions")}
        </Tab>
      </TabBox>
      {/*  */}
      {/*  */}
      <TabPanel style={activeTab === "1" ? {} : { display: "none" }}>
        <InsurerAccordionBlock>
          <Accordion title={t("insurerInformation")} openAccordion={false}>
            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("firstName")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {policy?.mainPolicy?.user?.firstNameGe ??
                  policy?.mainPolicy?.user?.firstName}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("lastName")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {policy?.mainPolicy?.user?.lastNameGe ??
                  policy?.mainPolicy?.user?.lastName}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("gender")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {policy?.mainPolicy?.user?.gender ??
                  policy?.mainPolicy?.user?.gender}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("personalNumber")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {policy?.mainPolicy?.user?.personalNumber}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("dateOfBirth")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {dayjs(policy?.mainPolicy?.user?.birthday).format(
                  "DD / MMM / YYYY"
                )}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("phone")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {policy?.mainPolicy?.user?.phone}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("email")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {policy?.mainPolicy?.user?.email}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("cityzenship")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {
                  countries?.find(
                    (x) => x.isoCode === policy?.mainPolicy?.user?.citizenship
                  )?.translations?.[lang].name
                }
              </OffetTextRightSide>
            </CheckInfoTextBox>
          </Accordion>
        </InsurerAccordionBlock>

        <Accordion title={t("policyInfo")} openAccordion={true}>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("insuranceCompany")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {
                policy?.mainPolicy?.package?.product?.company?.translations[
                  lang
                ]?.title
              }
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policyType")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {String(
                policy?.mainPolicy?.targetType?.match(/[A-Z][a-z]+/g)
              )?.replace(",", " ")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policyNumber")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.policyNumber}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("startDate")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.startDate).format("DD MMMM YYYY")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("endDate")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.endDate).format("DD MMMM YYYY")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("numberOfDays")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {insuranceDays} {t("day")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("orderId")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.orderItems[0]?.orderId}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("releaseDate")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.createdAt).format(
                "DD MMMM YYYY | HH:mm"
              )}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("status")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.status?.toUpperCase()}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policyPrice")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {discount === null
                ? `${policy?.price} ${currencySymbol(
                    policy?.mainPolicy?.compensationCurrency
                  )}`
                : `${
                    discount?.promoCode?.applyingDiscount?.actualAmount
                  }  ${currencySymbol(
                    policy?.mainPolicy?.compensationCurrency
                  )}`}{" "}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          {discount !== null && (
            <>
              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("promoCode")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {discount?.promoCode?.code}
                </OffetTextRightSide>
              </CheckInfoTextBox>
              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("promoCodeName")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {discount?.promoCode?.name}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              {discount?.promoCode?.applyingDiscount?.days && (
                <CheckInfoTextBox>
                  <OffetTextLeftSide>{t("discountByDays")}</OffetTextLeftSide>
                  <OffetTextRightSide>
                    {`${discount?.promoCode?.applyingDiscount?.days} ${t(
                      "day"
                    )}`}{" "}
                  </OffetTextRightSide>
                </CheckInfoTextBox>
              )}

              {discount?.promoCode?.applyingDiscount?.saving && (
                <CheckInfoTextBox>
                  <OffetTextLeftSide>{t("discountByAmount")}</OffetTextLeftSide>
                  <OffetTextRightSide>
                    {discount?.promoCode?.applyingDiscount?.saving}{" "}
                    {currencySymbol(policy?.mainPolicy?.compensationCurrency)}
                  </OffetTextRightSide>
                </CheckInfoTextBox>
              )}

              {discount?.promoCode?.applyingDiscount?.percentageDiscount
                ?.percentage && (
                <>
                  <CheckInfoTextBox>
                    <OffetTextLeftSide>
                      {t("discountByPercent")}
                    </OffetTextLeftSide>
                    <OffetTextRightSide>
                      {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.percentage} %`}{" "}
                    </OffetTextRightSide>
                  </CheckInfoTextBox>
                  <CheckInfoTextBox>
                    <OffetTextLeftSide>{t("maximumAmount")}</OffetTextLeftSide>
                    <OffetTextRightSide>
                      {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.maximumAmount} `}{" "}
                    </OffetTextRightSide>
                  </CheckInfoTextBox>
                </>
              )}
            </>
          )}

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("paymentSchedule")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {
                policy?.mainPolicy?.orderItems?.[0]?.paymentScheduleType
                  ?.translations?.[lang]?.title
              }
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("firstPayment")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {georgianFormatter.format(
                paymentScheduleData?.payments?.[0]?.amount as number
              )}{" "}
              {currencySymbol(policy?.mainPolicy?.compensationCurrency)}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          {policy?.mainPolicy?.orderItems?.[0]?.paymentScheduleType?.slug?.toLowerCase() ===
          "one-time" ? null : (
            <CheckInfoTextBox>
              <OffetTextLeftSide>{t("eachPayment")}</OffetTextLeftSide>
              <OffetTextRightSide>
                {georgianFormatter.format(
                  (paymentScheduleData?.payments || []).slice(-1)[0]?.amount
                )}{" "}
                {currencySymbol(policy?.mainPolicy?.compensationCurrency)}
              </OffetTextRightSide>
            </CheckInfoTextBox>
          )}

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("pan")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.orderItems?.[0]?.order?.payments?.map(
                (p) => {
                  return p?.status === "paid" ? p?.detailJson?.pan : "";
                }
              )}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("accountId")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.orderItems?.[0]?.order?.payments?.map(
                (p) => {
                  return p?.status === "paid" ? p?.detailJson?.accountId : "";
                }
              )}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          {policy?.mainPolicy?.deactivatedPolicy && (
            <div>
              <hr
                style={{
                  color: Colors.primaryBlack,
                }}
              />

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("dateOfDeactivation")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {dayjs(
                    policy?.mainPolicy?.deactivatedPolicy?.deactivatedDate
                  ).format("DD / MMMM / YYYY")}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>
                  {t("deactivationRequestDate")}
                </OffetTextLeftSide>
                <OffetTextRightSide>
                  {dayjs(
                    policy?.mainPolicy?.deactivatedPolicy?.createdAt
                  ).format("DD / MMMM / YYYY | HH:mm")}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("refundableAmount")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.refundAmount}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("earnedPremium")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.earnedPremium}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>
                  {t("deactivationPenalty")}
                </OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.cancellationPenalty}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("document")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  <CustomATag
                    href={policy?.mainPolicy?.deactivatedPolicy?.docUrl}
                    target={"_blank"}
                  >
                    {policy?.mainPolicy?.deactivatedPolicy?.docUrl}
                  </CustomATag>
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("decativationReason")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {
                    deactiveReasonArr?.find(
                      (x) =>
                        x?.id ===
                        policy?.mainPolicy?.deactivatedPolicy
                          ?.policyDeactivationReasonId
                    )?.translations?.[language]?.title
                  }
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("comment")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.comment}
                </OffetTextRightSide>
              </CheckInfoTextBox>
            </div>
          )}
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("petInformation")} openAccordion={false}>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("category")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.animalCategory?.translations[lang]?.name ??
                policy?.animalCategory?.translations[lang]?.name}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("firstName")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.petInformation?.name ?? policy?.petInformation?.name}
            </OffetTextRightSide>
          </CheckInfoTextBox>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("age")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.petInformation?.age?.year >= 1 &&
              policy?.petInformation?.age?.month === 0
                ? policy?.petInformation?.age?.year + " " + t("year")
                : policy?.petInformation?.age?.month >= 1 &&
                  policy?.petInformation?.age?.year === 0
                ? policy?.petInformation?.age?.month + " " + t("month")
                : policy?.petInformation?.age?.year >= 1 &&
                  policy?.petInformation?.age?.month >= 1
                ? policy?.petInformation?.age?.year +
                  " " +
                  t("year") +
                  ", " +
                  policy?.petInformation?.age?.month +
                  " " +
                  t("month")
                : null}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("breed")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.breed?.translations[lang]?.name ??
                policy?.breed?.translations[lang]?.name}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("color")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.petInformation?.color ?? policy?.petInformation?.color}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("gender")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.petInformation?.gender === "female"
                ? t("petFemale")
                : t("petMale")}
            </OffetTextRightSide>
          </CheckInfoTextBox>
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("whatIsCovered")} openAccordion={false}>
          {policy.mainPolicy?.package?.target?.petCoverages?.map(
            (item, index) => (
              <CheckInfoTextBox key={index}>
                <OffetTextLeftSide>
                  {item.translations?.[lang]?.title}
                </OffetTextLeftSide>
                <OffetTextRightSide>
                  {item.translations?.[lang]?.body}
                </OffetTextRightSide>
              </CheckInfoTextBox>
            )
          )}
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("importantInformation")} openAccordion={false}>
          <InsuranceExceptionsBlock>
            {policy.mainPolicy?.package?.target?.petImportantTerms?.map(
              (item, index) => (
                <MessageText key={index}>
                  <DoubleRightIcon />{" "}
                  <span>{item?.translations?.[lang]?.body}</span>
                </MessageText>
              )
            )}
          </InsuranceExceptionsBlock>
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("documents")} openAccordion={false}>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("insuranceContract")}</OffetTextLeftSide>
            <OffetTextRightSide>
              <CustomATag
                // href={policy?.mainPolicy?.package?.wordingFileUrl ?? policy?.mainPolicy?.productWordingUrl}
                href={policy?.mainPolicy?.productWordingUrl}
                target={"_blank"}
              >
                {/* {policy?.mainPolicy?.package?.wordingFileUrl ?? policy?.mainPolicy?.productWordingUrl} */}
                {policy?.mainPolicy?.productWordingUrl}
              </CustomATag>
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policyAgreement")}</OffetTextLeftSide>
            <OffetTextRightSide>
              <CustomATag
                href={policy?.mainPolicy?.policyPdfUrl}
                target={"_blank"}
              >
                {policy?.mainPolicy?.policyPdfUrl}
              </CustomATag>
            </OffetTextRightSide>
          </CheckInfoTextBox>
        </Accordion>
      </TabPanel>
      <TabPanel style={activeTab === "2" ? {} : { display: "none" }}>
        {policy?.mainPolicy?.orderItems?.[0]?.paymentScheduleType?.slug?.toLowerCase() ===
        "one-time" ? null : (
          <>
            {isPaymentScheduleDataLoading ? (
              <Loader horizontal={true} />
            ) : (
              <AdminPaymentSchedule scheduleData={paymentScheduleArray} />
            )}

            <hr />
          </>
        )}

        <TransactionsStyle>
          <TitleSection>
            <Title></Title>
            <Title>{t("company")}</Title>
            <Title>{t("policy")}#</Title>
            <Title>{t("date")}</Title>
            <Title>{t("value")}</Title>
            <TitleHideInMobile>{t("status")}</TitleHideInMobile>
          </TitleSection>
          {paymentTransactions?.map((item, index) => (
            <ContentStyles key={index}>
              <Title>
                <PetIconStyle />
              </Title>
              {/* <Title>{'TPL'}</Title> */}
              <Title>{item?.companyTranslations[lang]?.title}</Title>
              <Title>{item?.policyNumber}</Title>
              <Title>{dayjs(item.transferDate).format("DD.MM.YYYY")}</Title>
              <Title>
                {georgianFormatter.format(item.transferAmount as number)}
              </Title>
              <TitleHideInMobile>
                <StatusSpan>
                  {item.status}
                  {item.status && (
                    <StatusBall
                      style={{
                        backgroundColor:
                          item.status === "paid"
                            ? Colors.java
                            : item.status === "pending"
                            ? Colors.Primary
                            : Colors.primaryRed,
                      }}
                    />
                  )}
                </StatusSpan>
              </TitleHideInMobile>
            </ContentStyles>
          ))}
          <br />
          {paymentTransactions?.map((item, index) => (
            <ContentStylesMobile key={index}>
              <div>
                <Title>
                  <PetSmallIconStyle />
                  {item.carRegistrationNumber}
                </Title>
                <Title>
                  <StatusSpan>
                    {item.status && (
                      <StatusBall
                        style={{
                          backgroundColor:
                            item.status === "paid"
                              ? Colors.java
                              : item.status === "pending"
                              ? Colors.Primary
                              : Colors.primaryRed,
                        }}
                      />
                    )}
                  </StatusSpan>
                </Title>
              </div>
              <div>
                <Title>{t("policy")}#:</Title>
                <Title>{item.policyNumber}</Title>
              </div>
              <div>
                <Title>{t("date")}:</Title>
                <Title>{dayjs(item.transferDate).format("DD.MM.YYYY")}</Title>
              </div>
              <div>
                <Title>{t("value")}:</Title>
                <Title>
                  {georgianFormatter.format(item.transferAmount as number) +
                    currencySymbol(policy?.mainPolicy?.compensationCurrency)}
                </Title>
              </div>
            </ContentStylesMobile>
          ))}
        </TransactionsStyle>
      </TabPanel>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
    </Wrapper>
  );
};
//
// styles
const TabPanel = styled.div`
  margin-top: 10px;
  background-color: ${Colors.white};
  padding: 20px;
  padding-block: 20px;
  border-radius: 8px;
  box-shadow: ${Colors.white} 0px 30px 60px -12px inset,
    ${Colors.white} 0px 18px 36px -18px inset;
  ${Responsive.mobile} {
    margin-top: 20px;
  }
`;
const Tab = styled.button<{ $active?: boolean }>`
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px none;
  margin: 0px;
  margin-right: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.6);
  color: ${Colors.primaryBlack} !important;
  padding: 0px;
  margin-right: 30px;
  font-size: 18px;
  text-transform: none;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: all 200ms ease;
  ${({ $active = false }) =>
    $active ? `border-bottom-color: rgb(254, 95, 85);` : ""}

  &:last-child {
    margin-right: 0px;
  }
`;
const TabBox = styled.div`
  margin-top: 30px;
`;
const StatusBall = styled.span`
  height: 7px;
  width: 7px;
  border-radius: 50px;
  background-color: ${Colors.primaryGreen};
  user-select: none;
  pointer-events: none;
  padding: 0px !important;
  position: absolute;
  margin-left: 5px;
  margin-top: 7px;
  ${Responsive.mobile} {
    margin-top: 6px;
  }
  ${Responsive.mobile} {
    margin-top: 0px;
  }
`;
const StatusSpan = styled.span`
  ${Responsive.mobile} {
    margin-left: -13px;
    position: relative;
  }
`;
const PetSmallIconStyle = styled(PetSmallIcon)`
  ${Responsive.mobile} {
    margin-right: 20px;
  }
`;
const PetIconStyle = styled(PetIcon)`
  margin-top: -15px;
`;
const ContentStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  text-align: right;
  padding-inline: 20px;
  padding-top: 40px;
  margin-top: 40px;
  box-shadow: 160px -2px 0px ${Colors.grey};
  :nth-child(1) {
    box-shadow: none;
  }
  &:nth-child(2) {
    padding-top: 20px;
    margin-top: 0px;
    border-top: 0px;
    box-shadow: none;
  }
  div {
    text-align: left;
    :first-child {
      text-align: center;
    }
    :nth-child(8) {
      text-align: center;
    }
    :last-child {
      text-align: center;
    }
  }
  /*  */
  ${Responsive.laptop} {
    box-shadow: 100px -2px 0px ${Colors.grey};
    div {
      :nth-child(7) {
        text-align: center;
      }
    }
  }
  ${Responsive.mobile} {
    display: none;
  }
  ${Responsive.tablet} {
    box-shadow: 100px -2px 0px ${Colors.grey};
    div {
      :nth-child(7) {
        text-align: center;
      }
    }
  }
`;
const TitleSection = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  text-align: right;
  padding-inline: 20px;
  margin-bottom: 40px;
  div {
    color: ${Colors.primaryGrey};
    text-align: left;
    :nth-child(8) {
      text-align: center;
    }
    :last-child {
      text-align: center;
    }
  }
  /*  */
  ${Responsive.mobile} {
    display: none;
  }
  ${Responsive.tablet} {
    margin-bottom: 10px;
  }
`;
const Title = styled.div`
  font-size: 14px;
  color: ${Colors.primaryBlack};
  user-select: none;
  /* background-color: red; */
  /* responsive */
  ${Responsive.laptop} {
    font-size: 12px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    padding-left: 0px;
  }

  ${Responsive.mobile} {
    margin-left: 0;
    padding-left: 0;
    margin-top: 10px;
  }
`;
const TitleHideInMobile = styled(Title)`
  ${Responsive.mobile} {
    display: none;
  }
`;
const ContentStylesMobile = styled.div`
  display: none;
  ${Responsive.mobile} {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${Colors.secondaryGrey};
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    /*  */
    &:last-child {
      border: 0px;
      margin-bottom: 0px;
      padding-bottom: 10px;
    }
  }
`;
const TransactionsStyle = styled.div`
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0px 0px 15px 3px #88888811;
  box-shadow: 0px 0px 15px 3px #88888811;
  background-color: ${Colors.white};
  border-radius: 8px;
  overflow: hidden;

  ${Responsive.mobile} {
    padding-inline: 24px;
  }
`;
const TextAreaTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  &:focus {
    outline-color: ${Colors.primaryRed};
  }
`;
const GridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px 20px;
`;
const InsurerAccordionBlock = styled.div`
  .accordion-head-title {
    color: ${Colors.primaryRed};
  }
  svg {
    color: ${Colors.primaryRed};
  }
`;
const CustomATag = styled.a`
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-color: ${Colors.primaryRed};
  color: ${Colors.primaryBlack};
  /*  */
  &:hover {
    color: ${Colors.primaryBlack};
    opacity: 0.9;
  }
`;
const MessageText = styled.div`
  font-size: 14px;
  color: ${Colors.primaryBlack};
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  /*  */
  &:last-child {
    margin-bottom: 0px;
  }

  /* message text span */
  span {
    margin-left: 15px;
    color: ${Colors.primaryGrey};
  }

  /* message checkmark icon & svg */
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-left: -3px;
    color: ${Colors.primaryGrey};
  }

  /*  */
  ${Responsive.mobile} {
    /* message checkmark icon & svg */
    svg {
      margin-left: 0px;
      min-width: 15px;
      width: 15px;
    }

    /* message text span */
    span {
      margin-left: 20px;
      color: ${Colors.primaryGrey};
      line-height: 20px;
    }
  }
`;
const InsuranceExceptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const CheckInfoTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /*  */
  &:last-child {
    margin-bottom: 0px;
  }
  /*  */
  ${Responsive.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const OffetTextLeftSide = styled.span`
  color: ${Colors.primaryGrey};
  font-size: 14px;
  text-align: left;
  margin-right: 100px;
`;
const OffetTextRightSide = styled.span`
  color: ${Colors.primaryBlack};
  font-size: 14px;
  text-align: right;
`;
const Wrapper = styled.div``;
//
export default AdminTplSoldPolicyDetail;
