import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import { PromoCode } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";

const { RangePicker } = DatePicker;

const AdminCreateEditPromoCode: React.FC<{
  promoCodeRefetch: () => void;
  closeDrawer: () => void;
  promoCodeId?: number;
  editedPromoCodeData?: Partial<PromoCode>;
}> = ({ promoCodeRefetch, closeDrawer, promoCodeId, editedPromoCodeData }) => {
  //
  const { t } = useTranslation();
  //
  const { lang } = useAppSelector((root: { app: AppState }) => root.app);
  //
  const [promoCodeState, setPromoCodeState] = useState<Partial<PromoCode>>({
    activationDate: null,
    usageAmount: 1,
  });
  //
  const _status = promoCodeState.status?.toLowerCase();
  //
  const isEdited = editedPromoCodeData && promoCodeId;
  //
  const { data: prodcutsList, isLoading: isProdcutsListLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const setPromoCodeField = (field: keyof PromoCode, value: any) => {
    setPromoCodeState({
      ...promoCodeState,
      [field]: value,
    });
  };
  //
  //
  const validProductIdsForDaysAmount = prodcutsList
    ?.filter(
      (item) =>
        item.slug === "welcomer-to-georgia" ||
        item.slug === "travel-outside-georgia"
    )
    .map((i) => i.slug);
  //
  //
  const [updatePromoCode, { isLoading: isUpdateromoCodeLoading }] =
    api.useUpdatePromoCodeMutation();
  //
  const _updatePromoCode = async () => {
    //
    try {
      await updatePromoCode(promoCodeState).unwrap();
      //
      toast.success(`პრომოკოდი წარმატებით განახლდა`);
      //
      setPromoCodeState({
        activationDate: null,
        usageAmount: 1,
      });
      //
      closeDrawer();
      //
      promoCodeRefetch();
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const [createPromoCode, { isLoading: isCreatePromoCodeLoading }] =
    api.useCreatePromoCodeMutation();
  //
  const _createPromoCode_draft = async () => {
    //
    try {
      await createPromoCode({
        name: promoCodeState.name,
        code: promoCodeState.code,
        capacity: promoCodeState.capacity,
        usageAmount: promoCodeState.usageAmount,
        applyingDiscount: promoCodeState.applyingDiscount,
        status: "draft",
        startDate: promoCodeState.startDate,
        endDate: promoCodeState.endDate,
        activationDate: promoCodeState.activationDate || null,
        imageUrl: promoCodeState.imageUrl,
        productCategorySlugs: promoCodeState.productCategorySlugs,
        type: promoCodeState.type,
        options: promoCodeState.options,
        // published: promoCodeState.published,
        mustBeOwned: promoCodeState.mustBeOwned,
        userIds: [],
      }).unwrap();
      //
      toast.success(`პრომოკოდი წარმატებით შეიქმნა`);
      //
      setPromoCodeState({
        activationDate: null,
        usageAmount: 1,
      });
      //
      promoCodeRefetch();
      //
      closeDrawer();
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  useEffect(() => {
    if (editedPromoCodeData && promoCodeId) {
      setPromoCodeState(editedPromoCodeData);
    } else {
      setPromoCodeState({
        activationDate: null,
        usageAmount: 1,
      });
    }

    return () => {};
  }, [editedPromoCodeData, promoCodeId]);
  //
  const valiPromoCodeLetters = (value) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(value);
  };
  //
  const MainLoading = isCreatePromoCodeLoading || isUpdateromoCodeLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Space
      direction="vertical"
      size={"large"}
      style={{
        width: "100%",
      }}
    >
      {isEdited ? (
        <div>
          <Typography.Text>{t("status")}</Typography.Text>
          <Select
            size="large"
            style={{ width: "100%", minWidth: "200px" }}
            placeholder={`${t("applyProduct")}`}
            value={promoCodeState.status || ""}
            onChange={(value: string) => {
              setPromoCodeField("status", value);
            }}
            options={[
              ...(promoCodeState.status === "active"
                ? [
                    {
                      translations: {
                        en: {
                          title: "Deactivated",
                        },
                        ge: {
                          title: "გაუქმებული",
                        },
                      },
                      slug: "deactivated",
                    },
                  ]
                : [
                    {
                      translations: {
                        en: {
                          title: "Draft",
                        },
                        ge: {
                          title: "დრაფტი",
                        },
                      },
                      slug: "draft",
                    },
                    {
                      translations: {
                        en: {
                          title: "Prepared",
                        },
                        ge: {
                          title: "გამზადებული",
                        },
                      },
                      slug: "prepared",
                    },
                  ] || []
              ).map((status) => ({
                label: status.translations?.[lang]?.title,
                value: status.slug,
              })),
            ]}
          />
        </div>
      ) : null}

      <div>
        <Typography.Text>{t("enterPromoCode")}</Typography.Text>
        <Input
          disabled={
            _status === "deactivated" ||
            _status === "expired" ||
            _status === "active"
              ? true
              : false
          }
          size="large"
          placeholder={`${t("enterPromoCode")}`}
          value={promoCodeState.code || ""}
          onChange={(e) => {
            const value = e.target.value;

            if (valiPromoCodeLetters(value)) {
              setPromoCodeField("code", value);
            } else {
              toast.error("შეიყვანეთ მხოლოდ ლათინური ასოები და რიცხვები");
            }
          }}
        />
      </div>

      <div>
        <Typography.Text>{t("promoCodeName")}</Typography.Text>
        <Input
          disabled={
            _status === "deactivated" ||
            _status === "expired" ||
            _status === "active"
              ? true
              : false
          }
          size="large"
          placeholder={`${t("promoCodeName")}`}
          value={promoCodeState.name || ""}
          onChange={(e) => setPromoCodeField("name", e.target.value)}
        />
      </div>

      <div>
        <Typography.Text>{t("applyProduct")}</Typography.Text>
        <Select
          disabled={
            _status === "deactivated" ||
            _status === "expired" ||
            _status === "active"
              ? true
              : false
          }
          loading={isProdcutsListLoading}
          mode="multiple"
          size="large"
          style={{ width: "100%", minWidth: "200px" }}
          placeholder={`${t("applyProduct")}`}
          value={promoCodeState.productCategorySlugs?.map((x) => x)}
          onChange={(value: string[]) => {
            setPromoCodeField(
              "productCategorySlugs",
              value?.map((slug: string) => slug)
            );
          }}
          options={[
            ...(prodcutsList || []).map((product) => ({
              label: product?.slug?.replace(/-/g, " "),
              value: product?.slug,
            })),
          ]}
        />
      </div>

      <div>
        <Typography.Text>{t("startDate")}</Typography.Text>
        <DatePicker
          disabled={
            _status === "deactivated" ||
            _status === "expired" ||
            _status === "active"
              ? true
              : false
          }
          size="large"
          placeholder={t("startDate")}
          style={{ width: "100%" }}
          value={
            promoCodeState.startDate ? dayjs(promoCodeState.startDate) : null
          }
          onChange={(date) => {
            const dateString = dayjs(date).format("YYYY-MM-DD");
            if (dateString && date) {
              // @ts-ignore
              setPromoCodeField("startDate", dayjs.utc(dateString).format());
            } else {
              setPromoCodeField("startDate", undefined);
            }
          }}
        />
      </div>

      <div>
        <Typography.Text>{t("expireDate")}</Typography.Text>
        <DatePicker
          disabled={
            _status === "deactivated" || _status === "expired" ? true : false
          }
          size="large"
          placeholder={t("expireDate")}
          style={{ width: "100%" }}
          value={promoCodeState.endDate ? dayjs(promoCodeState.endDate) : null}
          onChange={(date) => {
            const dateString = dayjs(date).format("YYYY-MM-DD");
            if (dateString && date) {
              // @ts-ignore
              setPromoCodeField("endDate", dayjs.utc(dateString).format());
            } else {
              setPromoCodeField("endDate", undefined);
            }
          }}
        />

        {promoCodeState &&
        promoCodeState.startDate &&
        promoCodeState.endDate ? (
          <div
            style={{
              marginTop: "3px",
            }}
          >
            {`${t("days")}`}:{" "}
            <b>
              {dayjs(promoCodeState.endDate)?.diff(
                promoCodeState?.startDate,
                "day"
              ) + 1}
            </b>
          </div>
        ) : null}
      </div>

      <Radio.Group
        onChange={(e: RadioChangeEvent) =>
          setPromoCodeField("type", e.target.value)
        }
        value={promoCodeState.type}
      >
        <Space direction="vertical">
          {[
            { label: `${t("discountByAmount")}`, value: "amount" },
            { label: `${t("discountByPercent")}`, value: "percentage" },
            { label: `${t("discountByDays")}`, value: "days" },
          ].map((item, index) => (
            <Radio
              value={item.value}
              key={index}
              disabled={
                // don't judge me =)))
                item.value === "days"
                  ? (item.value === "days" &&
                      promoCodeState.productCategorySlugs &&
                      validProductIdsForDaysAmount &&
                      promoCodeState.productCategorySlugs.length ===
                        validProductIdsForDaysAmount.length &&
                      promoCodeState.productCategorySlugs.every((value) =>
                        validProductIdsForDaysAmount.includes(value)
                      )) ||
                    (promoCodeState.productCategorySlugs &&
                      promoCodeState.productCategorySlugs.length === 1 &&
                      validProductIdsForDaysAmount?.includes(
                        promoCodeState.productCategorySlugs[0]
                      ))
                    ? false
                    : true
                  : false ||
                    _status === "deactivated" ||
                    _status === "expired" ||
                    _status === "active"
              }
            >
              {item.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>

      {promoCodeState.type === "amount" && (
        <div>
          <Typography.Text>{t("amount")}</Typography.Text>
          <Input
            disabled={
              _status === "deactivated" ||
              _status === "expired" ||
              _status === "active"
                ? true
                : false
            }
            size="large"
            placeholder={`${t("amount")}`}
            value={promoCodeState.applyingDiscount?.amount || ""}
            onChange={(e) =>
              setPromoCodeField("applyingDiscount", {
                amount: parseInt(e.target.value),
              })
            }
          />
        </div>
      )}

      {promoCodeState.type === "percentage" && (
        <>
          <div>
            <Typography.Text>{t("percent")}</Typography.Text>
            <Input
              disabled={
                _status === "deactivated" ||
                _status === "expired" ||
                _status === "active"
                  ? true
                  : false
              }
              size="large"
              placeholder={`${t("percent")}`}
              value={
                promoCodeState.applyingDiscount?.percentageDiscount
                  ?.percentage || ""
              }
              onChange={(e) =>
                setPromoCodeField("applyingDiscount", {
                  percentageDiscount: {
                    ...promoCodeState.applyingDiscount?.percentageDiscount,
                    percentage: parseInt(e.target.value),
                  },
                })
              }
            />
          </div>

          <div>
            <Typography.Text>{t("maximumAmount")}</Typography.Text>
            <Input
              disabled={
                _status === "deactivated" ||
                _status === "expired" ||
                _status === "active"
                  ? true
                  : false
              }
              size="large"
              placeholder={`${t("maximumAmount")}`}
              value={
                promoCodeState.applyingDiscount?.percentageDiscount
                  ?.maximumAmount || ""
              }
              onChange={(e) =>
                setPromoCodeField("applyingDiscount", {
                  percentageDiscount: {
                    ...promoCodeState.applyingDiscount?.percentageDiscount,
                    maximumAmount: parseInt(e.target.value),
                  },
                })
              }
            />
          </div>
        </>
      )}

      {promoCodeState.type === "days" && (
        <div>
          <Typography.Text>{t("days")}</Typography.Text>
          <Input
            disabled={
              _status === "deactivated" ||
              _status === "expired" ||
              _status === "active"
                ? true
                : false
            }
            size="large"
            placeholder={`${t("days")}`}
            value={promoCodeState.applyingDiscount?.days || ""}
            onChange={(e) =>
              setPromoCodeField("applyingDiscount", {
                days: parseInt(e.target.value),
              })
            }
          />
        </div>
      )}

      <Divider />

      <Checkbox
        disabled={
          _status === "deactivated" ||
          _status === "expired" ||
          _status === "active"
            ? true
            : false
        }
        checked={
          promoCodeState.options?.policyInterval.hasOwnProperty("startDate") &&
          promoCodeState.options?.policyInterval.hasOwnProperty("endDate")
            ? true
            : false
        }
        onChange={(e) => {
          if (e.target.checked) {
            setPromoCodeField("options", {
              ...promoCodeState.options,
              policyInterval: {
                startDate: "",
                endDate: "",
              },
            });
          } else {
            setPromoCodeField("options", {
              ...promoCodeState.options,
              policyInterval: {},
            });
          }
        }}
      >
        {`${t("შეზღუდვა პოლისის პერიოდზე")}`}
      </Checkbox>

      {promoCodeState.options?.policyInterval.hasOwnProperty("startDate") &&
      promoCodeState.options?.policyInterval.hasOwnProperty("endDate") ? (
        <div>
          <Typography.Text>დან - მდე</Typography.Text>
          <RangePicker
            minDate={dayjs(promoCodeState.startDate) || undefined}
            placeholder={["დან", "მდე"]}
            size="large"
            style={{
              width: "100%",
            }}
            value={[
              promoCodeState.options.policyInterval.startDate
                ? dayjs(promoCodeState.options.policyInterval.startDate)
                : null,
              promoCodeState.options.policyInterval.endDate
                ? dayjs(promoCodeState.options.policyInterval.endDate)
                : null,
            ]}
            onChange={(_, dateStr) => {
              if (dateStr && dateStr[0] !== "" && dateStr[1] !== "") {
                //
                setPromoCodeField("options", {
                  ...promoCodeState.options,
                  policyInterval: {
                    // @ts-ignore
                    startDate: dayjs.utc(dateStr[0]).format(),
                    // @ts-ignore
                    endDate: dayjs.utc(dateStr[1]).format(),
                  },
                });
              } else {
                setPromoCodeField("options", {
                  ...promoCodeState.options,
                  policyInterval: {
                    startDate: "",
                    endDate: "",
                  },
                });
              }
            }}
          />
        </div>
      ) : null}

      <Checkbox
        disabled={
          _status === "deactivated" || _status === "expired" ? true : false
        }
        checked={
          promoCodeState.capacity === 0
            ? false
            : promoCodeState.capacity !== undefined
        }
        onChange={(e) => {
          if (e.target.checked) {
            setPromoCodeField("capacity", null);
          } else {
            setPromoCodeField("capacity", undefined);
          }
        }}
      >
        {`${t("limitedAmountOfCoupon")}`}
      </Checkbox>
      {promoCodeState.capacity === 0
        ? null
        : promoCodeState.capacity !== undefined && (
            <div>
              <Typography.Text>{t("amountOfCoupon")}</Typography.Text>
              <Input
                disabled={
                  _status === "deactivated" || _status === "expired"
                    ? true
                    : false
                }
                placeholder={`${t("amountOfCoupon")}`}
                size="large"
                type="number"
                value={promoCodeState.capacity || undefined}
                onChange={(e) =>
                  setPromoCodeField("capacity", parseInt(e.target.value))
                }
                // @ts-ignore
                onWheel={(e) => e.target?.blur()}
              />
            </div>
          )}

      <div>
        <Typography.Text>{t("amountUsageOfUser")}</Typography.Text>
        <Input
          disabled={
            _status === "deactivated" ||
            _status === "expired" ||
            _status === "active"
              ? true
              : false
          }
          placeholder={`${t("amountUsageOfUser")}`}
          size="large"
          type="number"
          value={promoCodeState.usageAmount || undefined}
          onChange={(e) =>
            setPromoCodeField("usageAmount", parseInt(e.target.value))
          }
          // @ts-ignore
          onWheel={(e) => e.target?.blur()}
        />
      </div>

      <UploadFile
        disabled={
          _status === "deactivated" || _status === "expired" ? true : false
        }
        label={t("icon")}
        onChange={(props) => setPromoCodeField("imageUrl", props.url)}
        defaultFileName={promoCodeState?.imageUrl || ""}
      />

      <Divider />

      <Checkbox
        checked={promoCodeState.mustBeOwned || false}
        onChange={(e) => {
          if (e.target.checked) {
            setPromoCodeField("mustBeOwned", true);
          } else {
            setPromoCodeField("mustBeOwned", false);
          }
        }}
      >
        {`${t("appearOnTheWebsite")}`}
      </Checkbox>

      <Button
        style={{
          width: "100%",
        }}
        type="primary"
        htmlType="submit"
        size="large"
        onClick={isEdited ? _updatePromoCode : _createPromoCode_draft}
        disabled={
          promoCodeState.code &&
          promoCodeState.name &&
          promoCodeState.usageAmount &&
          promoCodeState.applyingDiscount &&
          promoCodeState.startDate &&
          promoCodeState.endDate &&
          promoCodeState.imageUrl &&
          promoCodeState.productCategorySlugs &&
          (promoCodeState.type === "percentage"
            ? promoCodeState?.applyingDiscount?.percentageDiscount
                ?.maximumAmount &&
              promoCodeState?.applyingDiscount?.percentageDiscount?.percentage
            : promoCodeState.type === "amount"
            ? promoCodeState?.applyingDiscount?.amount
            : promoCodeState.type === "days"
            ? promoCodeState?.applyingDiscount.days
            : false)
            ? false
            : true
        }
      >
        {isEdited ? `${t("update")}` : `${t("activateNow")}`}
      </Button>

      {/* <Button
        style={{
          width: "100%",
        }}
        type="default"
        size="large"
      >
        {`${t("saveForLater")}`}
      </Button> */}
    </Space>
  );
};

export default AdminCreateEditPromoCode;
