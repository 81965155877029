import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";

import GlobalFonts from "./assets/fonts/fonts";
import { useAppDispatch, useAppSelector } from "./store";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// admin side
import AdminLogin from "./Admin/admin_screens/admin_auth/Admin_login";
import AdminProfile from "./Admin/admin_screens/admin_auth/Admin_profile";
import AdminDashboard from "./Admin/admin_screens/Admin_dashboard";
import AdminCreateNotification from "./Admin/admin_screens/admin_notification/Admin_create_notification";
import AdminNotification from "./Admin/admin_screens/admin_notification/Admin_notification";
import AdminNotificationDetail from "./Admin/admin_screens/admin_notification/Admin_notification_detail";
import AdminTplInsurance from "./Admin/admin_screens/admin_product/Admin_tpl_insurance";
import AdminTravelInsurance from "./Admin/admin_screens/admin_product/Admin_travel_insurance";
import AdminLayout from "./Admin/admin_screens/nav/Admin_layout";

// some pages & components

import AdminCompanyDetail from "./Admin/admin_screens/Admin_companies/Admin_company_detail";
import AdminProductPanagment from "./Admin/admin_screens/admin_product/travel_admin/Admin_product_managment";
import AdminTravelProductEdit from "./Admin/admin_screens/admin_product/travel_admin/Admin_travel_product_edit";
import AdminTravelProductList from "./Admin/admin_screens/admin_product/travel_admin/Admin_travel_product_list";
import api from "./api";
import Loader from "./components/Loader";
import NotFound404 from "./components/NotFound404";

import { ConfigProvider, theme } from "antd";
import AdminAddons from "./Admin/admin_screens/admin_addon/AdminAddons";
import AdminFraniUserEdit from "./Admin/admin_screens/admin_auth/Admin_frani_user_edit";
import AdminFraniUsers from "./Admin/admin_screens/admin_auth/Admin_frani_users";
import AdminUserProfile from "./Admin/admin_screens/admin_auth/Admin_user_profile";
import AdminUsers from "./Admin/admin_screens/Admin_companies/AdminAuthorizedCompanies";
import AdminCompanyUsersEdit from "./Admin/admin_screens/Admin_companies/AdminCompanyUsersEdit";
import AdminPolicyDeactivationReasons from "./Admin/admin_screens/admin_deactivate_reasons/AdminDeactivateReasons";
import AdminPolicyDeactivationReasonsDetails from "./Admin/admin_screens/admin_deactivate_reasons/AdminDeactivateReasonsDetails";
import AdminWebDocuments from "./Admin/admin_screens/Admin_documents/Admin_web_documents";
import AdminFeesPaid from "./Admin/admin_screens/Admin_fees_paid";
import AdminTraveFranchise from "./Admin/admin_screens/Admin_franchise/AdminTraveFranchise";
import AdminInformationSheets from "./Admin/admin_screens/admin_information_sheets/AdminInformationSheet";
import AdminManageEmails from "./Admin/admin_screens/Admin_manage_emails";
import AdminCompanyPaymentList from "./Admin/admin_screens/admin_payment/AdminCompanyPaymentList";
import AdminPaymentScheduleTypeDetail from "./Admin/admin_screens/admin_payment_schedule_types/AdminPaymentScheduleTypeDetail";
import AdminPaymentScheduleTypes from "./Admin/admin_screens/admin_payment_schedule_types/AdminPaymentScheduleTypes";
import AdminTravelMultiDayEdit from "./Admin/admin_screens/admin_product/Admin_travel_multi_day_edit";
import AdminTravelMultiDay from "./Admin/admin_screens/admin_product/Admin_travel_multi_days";
import AdminCompanySoldPolicies from "./Admin/admin_screens/admin_product/AdminCompanySoldPolicies";
import AdminCompanySoldPoliciesDetail from "./Admin/admin_screens/admin_product/AdminCompanySoldPoliciesDetail";
import AdminBusinessModule from "./Admin/admin_screens/admin_product/business_admin/AdminBusinessModule";
import AdminBusinessModuleDetails from "./Admin/admin_screens/admin_product/business_admin/AdminBusinessModuleDetails";
import AdminCriticalCurrencies from "./Admin/admin_screens/admin_product/critical_admin/AdminCriticalCurrencies";
import AdminCriticalLimits from "./Admin/admin_screens/admin_product/critical_admin/AdminCriticalLimits";
import AdminCriticalManagePages from "./Admin/admin_screens/admin_product/critical_admin/AdminCriticalManagePages";
import AdminCriticalManagePagesEdit from "./Admin/admin_screens/admin_product/critical_admin/AdminCriticalManagePagesEdit";
import AdminCriticalManageRisks from "./Admin/admin_screens/admin_product/critical_admin/AdminCriticalManageRisks";
import AdminPetBreed from "./Admin/admin_screens/admin_product/pet_admin/AdminPetBreed";
import AdminPetCategories from "./Admin/admin_screens/admin_product/pet_admin/AdminPetCategories";
import AdminPetManagePages from "./Admin/admin_screens/admin_product/pet_admin/AdminPetManagePages";
import AdminPetManagePagesEdit from "./Admin/admin_screens/admin_product/pet_admin/AdminPetManagePagesEdit";
import AdminPetServices from "./Admin/admin_screens/admin_product/pet_admin/AdminPetServices";
import AdminPetServiceSecond from "./Admin/admin_screens/admin_product/pet_admin/AdminPetServiceSecond";
import AdminPetSoldPolicyDetail from "./Admin/admin_screens/admin_product/pet_admin/AdminPetSoldPolicyDetail";
import AdminPetProductEdit from "./Admin/admin_screens/admin_product/pet_admin/pet_product_store/AdminPetProductEdit";
import AdminTplLimits from "./Admin/admin_screens/admin_product/tpl_admin/Admin_tpl_limits";
import AdminTplManagePages from "./Admin/admin_screens/admin_product/tpl_admin/Admin_tpl_manage_pages";
import AdminTplManagePagesEdit from "./Admin/admin_screens/admin_product/tpl_admin/Admin_tpl_manage_pages_edit";
import AdminTplCurrencies from "./Admin/admin_screens/admin_product/tpl_admin/AdminTplCurrencies";
import AdminTplExceptionCars from "./Admin/admin_screens/admin_product/tpl_admin/AdminTplExceptionCars";
import AdminTplProductList from "./Admin/admin_screens/admin_product/tpl_admin/AdminTplProductList";
import AdminTplServices from "./Admin/admin_screens/admin_product/tpl_admin/AdminTplServices";
import AdminTplSoldPolicyDetail from "./Admin/admin_screens/admin_product/tpl_admin/AdminTplSoldPolicyDetail";
import AdminTplProductEdit from "./Admin/admin_screens/admin_product/tpl_admin/tpl_product_store/AdminTplProductEdit";
import AdminManageTravelPages from "./Admin/admin_screens/admin_product/travel_admin/Admin_manage_travel_pages";
import AdminManageTravelPagesEdit from "./Admin/admin_screens/admin_product/travel_admin/Admin_manage_travel_pages_edit";
import AdminTravelReports from "./Admin/admin_screens/admin_product/travel_admin/Admin_travel_reports";
import AdminWelcomerAddons from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerAddons";
import AdminWelcomerCurrencies from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerCurrencies";
import AdminWelcomerFranchise from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerFranchise";
import AdminWelcomerLimits from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerLimits";
import AdminWelcomerManagePages from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerManagePages";
import AdminWelcomerManagePagesEdit from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerManagePagesEdit";
import AdminWelcomerProductEdit from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerProduct/AdminWelcomerProductEdit";
import AdminWelcomerRisks from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerRisks";
import AdminWelcomerSoldPolicyDetail from "./Admin/admin_screens/admin_product/welcomer_admin/AdminWelcomerSoldPolicyDetail";
import AdminRisks from "./Admin/admin_screens/admin_risk/AdminRisks";
import AdminAboutUs from "./Admin/admin_screens/admin_web_pages/Admin_about_us";
import AdminLandingManageTexts from "./Admin/admin_screens/admin_web_pages/Admin_landing_manage_texts";
import AdminManageFaq from "./Admin/admin_screens/admin_web_pages/Admin_manage_faq";
import AdminManagePages from "./Admin/admin_screens/admin_web_pages/Admin_manage_pages";
import AdminProductsEdit from "./Admin/admin_screens/admin_web_pages/Admin_products_edit";
import AdminProductsPage from "./Admin/admin_screens/admin_web_pages/Admin_products_page";
import AdminPartnerDetail from "./Admin/admin_screens/partners/AdminPartnerDetail";
import AdminPartners from "./Admin/admin_screens/partners/AdminPartners";
import Fonts from "./assets/Fonts";
import { getAppThemeLocalStorage } from "./store/appThemeStorage";
import { clearLoginData } from "./store/authSlice";
import AdminPromoCodes from "./Admin/admin_screens/admin_promo_codes/AdminPromoCodes";
import AdminWebUserList from "./Admin/admin_screens/admin_web_users/AdminWebUserList";
import AdminLandingSlider from "./Admin/admin_screens/admin_web_pages/AdminLandingSlider";

//
dayjs.extend(utc);
//
//
//@ts-ignore
require("dayjs/locale/ka");
//@ts-ignore
require("dayjs/locale/en");
//
// admin private
function AdminPrivateRoute({ children }: any) {
  const auth = useAppSelector((data) => data.auth);
  if (auth.userLoading) {
    return <Loader />;
  }
  return auth.token &&
    auth.user &&
    auth.user.userType !== "User" &&
    (auth.user.userType === "Frani-Admin" ||
      "Frani-Product-Owner" ||
      auth.user.companyUsers.some((value) => {
        const hasAdminRole = [
          "Frani-Admin",
          "Frani-Product-Owner",
          "Insurance-Admin",
          "Insurance-Company-Editor",
          "Insurance-Financial-Viewer",
          "Insurance-Product-Editor",
        ].includes(value.role);

        return hasAdminRole;
      })) ? (
    children
  ) : (
    <Navigate replace to="/" state={{ from: "routeProps.location" }} />
  );
}

const App: React.FC = () => {
  //
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  //
  const auth = useAppSelector((data) => data.auth);
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  //
  useEffect(() => {
    if (lang === "ge") {
      dayjs.locale("ka");
    }
    //
    if (lang === "en") {
      dayjs.locale("en");
    }
  }, [lang]);

  //
  //
  const dispatch = useAppDispatch();
  useEffect(() => {
    // TODO this gets called twice, even if `auth.token`'s value doesn't change
    if (auth.token) {
      const loadProfile = async () => {
        const {
          // status,
          // data,
          error: loadProfileError,
          //refetch
        } = await dispatch(
          api.endpoints.profile.initiate({ token: auth.token })
        );
        if (loadProfileError) {
          // console.log('login via token failed', { loadProfileError })
          dispatch(clearLoginData());
        }
      };
      loadProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  //
  //
  //
  useEffect(() => {
    if (lang === "ge") {
      document.body.style.setProperty(
        "font-family",
        `${Fonts.Helvetica_Neue_LT_GEO_55_roman}`,
        "important"
      );
    }
    //
    if (lang === "en") {
      document.body.style.setProperty(
        "font-family",
        `${Fonts.helvetica_Neue_regular}`,
        "important"
      );
    }
  }, [lang]);
  //
  //
  const { defaultAlgorithm, darkAlgorithm } = theme;
  //
  //
  return (
    <ConfigProvider
      theme={{
        algorithm:
          getAppThemeLocalStorage() === "dark"
            ? darkAlgorithm
            : defaultAlgorithm,
        token: {
          fontFamily: "inherit",
        },
      }}
    >
      <BrowserRouter>
        <GlobalFonts />

        <Routes>
          <Route path="/" element={<AdminLogin />} />
          {/* admin dashboard screen */}
          <Route
            path="/dashboard"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminDashboard />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin product management */}
          <Route
            path="/product-management"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminProductPanagment />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin product management */}
          <Route
            path="/company-detail/:companyId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCompanyDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin travel product list */}
          <Route
            path="/product-list"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTravelProductList />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin product edit */}
          <Route
            path="/travel-product-edit/:travelProductId"
            element={
              <AdminPrivateRoute>
                <AdminTravelProductEdit />
              </AdminPrivateRoute>
            }
          />
          {/* admin profile screen & unused */}
          <Route
            path="/profile"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminProfile />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/*  */}
          <Route
            path="/notification"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminNotification />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/*  */}
          <Route
            path="/notification/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminNotificationDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/*  */}
          <Route
            path="/produts/travel-insurance"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTravelInsurance />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/*  */}
          <Route
            path="/produts/tpl-insurance"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplInsurance />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/*  */}
          <Route
            path="/create-notification"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCreateNotification />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/*  */}
          <Route
            path="/manage-product-emails"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminManageEmails />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* company sold policies list */}
          <Route
            path="/company-sold-policies"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCompanySoldPolicies />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* company sold policy detail */}
          <Route
            path="/company-sold-policy/:policyId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCompanySoldPoliciesDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/tpl-sold-policy-detail/:policyId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplSoldPolicyDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/welcomer-sold-policy-detail/:policyId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerSoldPolicyDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/pet-sold-policy-detail/:policyId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetSoldPolicyDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* company payment list*/}
          <Route
            path="/company-payment-history"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCompanyPaymentList />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin risks */}
          <Route
            path="/risks"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminRisks />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin addons */}
          <Route
            path="/addons"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminAddons />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin travel limit */}
          <Route
            path="/travel-franchise"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTraveFranchise />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin customers  */}
          <Route
            path="/authorized-companies"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminUsers />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin customers  */}
          <Route
            path="/authorized-company-users/:companyId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCompanyUsersEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin manage travel pages  */}
          <Route
            path="/manage-travel-pages"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminManageTravelPages />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin manage travel pages edit  */}
          <Route
            path="/manage-travel-pages-edit/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminManageTravelPagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin travel report list  */}
          <Route
            path="/travel-reports"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTravelReports />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin documents  */}
          <Route
            path="/web-documents"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWebDocuments />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin user profile  */}
          <Route
            path="/user-profile"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminUserProfile />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin fees paid  */}
          <Route
            path="/fees-paid"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminFeesPaid />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/*web users controll  */}
          <Route
            path="/web-users"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWebUserList />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin company users controll  */}
          <Route
            path="/frani-users"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminFraniUsers />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin frani user detail  */}
          <Route
            path="/frani-user-edit/:userId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminFraniUserEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin travel multi day  */}
          <Route
            path="/travel-multi-days"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTravelMultiDay />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin travel multi day edit */}
          <Route
            path="/travel-multi-day-edit/:dayId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTravelMultiDayEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* manage web pages */}
          <Route
            path="/manage-pages"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminManagePages />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* manage web pages */}
          <Route
            path="/edit-product-detail"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminProductsPage />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/*  */}
          <Route
            path="/product-page-edit/:productType"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminProductsEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* landing page manage texts */}
          <Route
            path="/landing-manage-texts"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminLandingManageTexts />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin about us manage*/}
          <Route
            path="/about-us-edit"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminAboutUs />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* manage faq page */}
          <Route
            path="/manage-faq"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminManageFaq />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl limits */}
          <Route
            path="/tpl-limits"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplLimits />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl limits */}
          <Route
            path="/tpl-currencies"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplCurrencies />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl manage pages */}
          <Route
            path="/tpl-manage-pages"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplManagePages />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl manage pages edit by id */}
          <Route
            path="/manage-tpl-pages-edit/:slug/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl manage pages edit page info */}
          <Route
            path="/manage-tpl-pages-edit/:slug/"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl product tab */}
          <Route
            path="/tpl-product-edit/:tplProductId"
            element={
              <AdminPrivateRoute>
                <AdminTplProductEdit />
              </AdminPrivateRoute>
            }
          />

          {/* admin tpl product tab */}
          <Route
            path="/pet-product-edit/:petProductId"
            element={
              <AdminPrivateRoute>
                <AdminPetProductEdit />
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl product tab */}
          <Route
            path="/tpl-product-list"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplProductList />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin tpl product tab */}
          <Route
            path="/tpl-exception-cars"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplExceptionCars />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/payment-schedule-types"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPaymentScheduleTypes />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/payment-schedule-types-detail/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPaymentScheduleTypeDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/tpl-services"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminTplServices />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer risks */}
          <Route
            path="/welcomer-risks"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerRisks />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer addons */}
          <Route
            path="/welcomer-addons"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerAddons />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer franchise */}
          <Route
            path="/welcomer-franchise"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerFranchise />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer manage pages */}
          <Route
            path="/manage-welcomer-pages"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerManagePages />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer manage pages edit */}
          <Route
            path="/manage-welcomer-pages-edit/:slug/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer manage pages edit page info */}
          <Route
            path="/manage-welcomer-pages-edit/:slug/"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer limits */}
          <Route
            path="/welcomer-limits"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerLimits />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin welcomer currencies */}
          <Route
            path="/welcomer-currencies"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminWelcomerCurrencies />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin welcomer product edit */}
          <Route
            path="/welcomer-product-edit/:welcomerProductId"
            element={
              <AdminPrivateRoute>
                <AdminWelcomerProductEdit />
              </AdminPrivateRoute>
            }
          />

          {/* business module list*/}
          <Route
            path="/business-module"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminBusinessModule />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* business module list*/}

          {/* business module list detail */}
          <Route
            path="/business-module/:itemId"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminBusinessModuleDetails />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* business module list detail */}

          {/* admin pet categories */}
          <Route
            path="/pet-categories"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetCategories />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin pet breed */}
          <Route
            path="/pet-breed"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetBreed />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin pet services */}
          <Route
            path="/pet-services"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetServices />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin pet package services */}
          <Route
            path="/pet-package-services"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetServiceSecond />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />
          {/* admin pet manage pages */}
          <Route
            path="/pet-manage-pages"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetManagePages />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/manage-pet-pages-edit/:slug/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/manage-pet-pages-edit/:slug/"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPetManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin critical limits */}
          <Route
            path="/critical-limits"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCriticalLimits />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin critical currencies */}
          <Route
            path="/critical-currencies"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCriticalCurrencies />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin critical manage risks */}
          <Route
            path="/critical-manage-risks"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCriticalManageRisks />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin critical manage pages */}
          <Route
            path="/critical-manage-pages"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCriticalManagePages />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin critical manage pages edit */}
          <Route
            path="/manage-critical-pages-edit/:slug/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCriticalManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* admin critical manage pages edit page info */}
          <Route
            path="/manage-critical-pages-edit/:slug/"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminCriticalManagePagesEdit />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* common configuration screens */}

          <Route
            path="/:slug/information-sheet"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminInformationSheets />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/:slug/policy-deactivation-reasons"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPolicyDeactivationReasons />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/:slug/policy-deactivation-reason-detail/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPolicyDeactivationReasonsDetails />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* common configuration screens */}

          <Route
            path="/landing-slider"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminLandingSlider />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/frani-partners"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPartners />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/frani-partner-detail/:id"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPartnerDetail />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          <Route
            path="/promo-codes"
            element={
              <AdminPrivateRoute>
                <AdminLayout>
                  <AdminPromoCodes />
                </AdminLayout>
              </AdminPrivateRoute>
            }
          />

          {/* some routes */}
          <Route path="*" element={<NotFound404 />} />
          {/* some routes */}
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
